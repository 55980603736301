
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import KeySystemForm from "./KeySystemForm.vue";
import * as SystemServices from "@/api/helpers/System";
import { Count } from "@/api/Gondor";
import * as KeySystemServices from "@/api/helpers/KeySystem";
import Notify from "@/utils/notifications";
import KeySystem from "@/models/KeySystem";
import DeletePopover from "./DeletePopover.vue";
import * as CardServices from "@/api/helpers/Card";

interface System {
  id: number;
  name: string;
  url: string;
  keyId: string;
  active: boolean;
  showImprovement: boolean;
  createdAt: string;
  updatedAt: string;
  countryId: number;
}

@Component({
  components: {
    KeySystemForm,
    DeletePopover,
  },
})
export default class KeyDrawer extends Vue {
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: true }) system!: System;
  @Ref() readonly formRef!: KeySystemForm;

  create = true;
  loading = false;
  keySystems: Count<KeySystemServices.KeySystem> = {
    count: 0,
    rows: [],
  };

  keySystem = new KeySystem();

  showDialog = false;
  loadingForm = false;

  createKey() {
    this.keySystem = new KeySystem();
    this.handleDialog(true);
    this.create = true;
  }

  editKey(keyId: number) {
    const keyEdit = this.keySystems.rows.find(key => key.id === keyId);
    if (keyEdit) {
      this.keySystem = {
        dataType: keyEdit.dataType,
        key: keyEdit.key,
        name: keyEdit.name,
        systemId: keyEdit.systemId,
        createdAt: keyEdit.createdAt,
        description: keyEdit.description as string | undefined,
        id: keyEdit.id,
        updatedAt: keyEdit.updatedAt,
      };
      this.handleDialog(true);
      this.create = false;
    }
  }

  async deleteKey(keyId: number) {
    const key = this.keySystems.rows.find(key => key.id === keyId);
    if (!key) return;

    const systemCards = await CardServices.find({
      order: "order:asc",
      systemId: key.systemId,
    });

    let matchCount = 0;

    systemCards.data.data.forEach(card => {
      card.settings.forEach(setting => {
        if (setting.key === key.key) {
          matchCount++;
        }
      });
    });

    const result = {
      found: matchCount > 0,
      quantity: matchCount,
    };

    if (result.found) {
      Notify.warning(
        `Esta llave se esta usando en: ${result.quantity} ${
          result.quantity > 1 ? "tarjetas" : "tarjeta"
        }`
      );
      return;
    }

    await SystemServices.deleteKeySystem(key.systemId, key.id);
    await this.findKeys(key.systemId);
  }

  closeDrawer() {
    this.$emit("closeDrawer", true);
  }

  handleDialog(status: boolean) {
    this.showDialog = status;
  }

  findKeys(systemId: number) {
    this.loading = true;
    SystemServices.keySystem(systemId)
      .then(res => {
        this.keySystems = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  newKeySystem() {
    this.loadingForm = true;
    this.formRef
      .validateForm()
      .then(() => {
        const { name, key, dataType, description } = this.keySystem;
        SystemServices.createKeySystem(this.system.id, {
          dataType,
          key,
          name,
          description,
        })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.loadingForm = false;
            this.handleDialog(false);
            this.findKeys(this.system.id);
          });
      })
      .catch(() => {
        this.loadingForm = false;
      });
  }

  updateKeySystem() {
    this.loadingForm = true;
    this.formRef
      .validateForm()
      .then(() => {
        const { name, key, dataType, description, id } = this.keySystem;
        if (id !== undefined) {
          SystemServices.updateKeySystem(this.system.id, id, {
            dataType,
            key,
            name,
            description,
          })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.loadingForm = false;
              this.handleDialog(false);
              this.findKeys(this.system.id);
            });
        }
      })
      .catch(() => {
        this.loadingForm = false;
      });
  }

  @Watch("system.id", { immediate: true })
  onSystemChange(newSystemId: number) {
    if (newSystemId) {
      this.findKeys(newSystemId);
    }
  }

  get titleDrawer() {
    return `Llaves ${this.system.name}`;
  }

  get titleDialog() {
    return `${this.create ? "Crear" : "Actualizar"} llave`;
  }
}
