
import { Vue, Component, Prop } from "vue-property-decorator";
import KeySystem from "@/models/KeySystem";
import { ElForm } from "element-ui/types/form";

interface FormRule {
  required?: boolean;
  type?: string;
  min?: number;
  max?: number;
  validator?: (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => void;
  trigger?: string;
  pattern?: RegExp;
  message: string;
}

@Component
export default class KeySystemForm extends Vue {
  @Prop({ required: true }) keySystem!: KeySystem;

  dataTypes = [
    {
      label: "Fecha",
      value: "date",
    },
    {
      label: "Id",
      value: "id",
    },
    {
      label: "Lógico",
      value: "boolean",
    },
    {
      label: "Número",
      value: "number",
    },
    {
      label: "Texto",
      value: "string",
    },
    {
      label: "Json",
      value: "json",
    },
    {
      label: "Texto largo",
      value: "longText",
    },
  ];

  rules: { [key: string]: FormRule[] } = {
    name: [
      {
        required: true,
        message: "Este campo es requerido",
        trigger: "blur",
      },
    ],
    key: [
      {
        required: true,
        message: "Este campo es requerido",
        trigger: "blur",
      },
    ],
    dataType: [
      {
        required: true,
        message: "Este campo es requerido",
        trigger: "change",
      },
    ],
    description: [
      {
        validator: (rule, value, callback) => {
          const dataType = this.keySystem.dataType;
          if (dataType === "json") {
            try {
              JSON.parse(value);
              callback();
            } catch (e) {
              callback("El campo debe ser un JSON válido");
            }
          } else {
            callback();
          }
        },
        message: "",
        trigger: "blur",
      },
    ],
  };

  validateForm(): Promise<boolean> {
    return (this.$refs.keySystemForm as ElForm).validate();
  }

  validator(rule: any, value: any, callback: any) {
    const dataType = this.keySystem.description;
    if (dataType === "json") {
      try {
        JSON.parse(value);
        callback();
      } catch (e) {
        callback(new Error("El campo debe ser un JSON válido"));
      }
    } else {
      callback();
    }
  }
}
