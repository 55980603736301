
import { Component, Prop, Vue } from "vue-property-decorator";
import Regex from "@/utils/regex";
import * as UserServices from "@/api/helpers/User";
import User from "@/models/User";
import Notify from "@/utils/notifications";

@Component
export default class UserDrawer extends Vue {
  @Prop() user!: User;
  @Prop() create!: boolean;
  @Prop() show!: boolean;

  loading = false;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    lastname: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    email: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: Regex.email,
        message: "No es un correo válido.",
      },
    ],
    password: [
      {
        required: this.create,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 6,
        max: 12,
        message: "Debe contener entre 6 y 12 caracteres.",
      },
    ],
  };

  createUser() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        UserServices.create(this.user)
          .then(() => {
            this.$emit("newUser");
            Notify.successful("Usuario creado exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateUser() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.user && this.user.id)
          UserServices.update(this.user.id, this.user)
            .then(() => {
              Notify.successful("Usuario actualizado exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    return this.create ? "Crear nuevo usuario" : "Actualizar usuario";
  }

  get showDrawer() {
    return this.show;
  }
}
