
import { Component, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import KeySecretPopover from "@/components/KeySecretPopover.vue";
import Notify from "@/utils/notifications";
import * as UserServices from "@/api/helpers/User";
import User from "@/models/User";
import UserDrawer from "@/components/TheUserDrawer.vue";
import * as SystemServices from "@/api/helpers/System";
import System from "@/models/System";
import SystemDrawer from "@/components/TheSystemDrawer.vue";
import * as PermissionServices from "@/api/helpers/Permission";
import Permission from "@/models/Permission";
import PermissionDrawer from "@/components/ThePermissionDrawer.vue";
import * as ColumnServices from "@/api/helpers/Column";
import Column from "@/models/Column";
import ColumnForm from "@/components/ColumnForm.vue";
import * as CountryServices from "@/api/helpers/Country";
import ColumnDrawer from "@/components/TheColumnDrawer.vue";
import OfferDrawer from "@/components/TheOffertDrawer.vue";
import KeySystemDrawer from "@/components/TheKeySystemDrawer.vue";
import _ from "underscore";

interface RankingSystem {
  id: number;
  ranking: number;
}

@Component({
  components: {
    UserDrawer,
    SystemDrawer,
    PermissionDrawer,
    ColumnForm,
    DeletePopover,
    KeySecretPopover,
    ColumnDrawer,
    KeySystemDrawer,
    OfferDrawer,
  },
})
export default class Setting extends Vue {
  fullscreenLoading = true;
  create = false;
  userLoading = false;
  userDrawer = false;
  user = new User();
  users: Array<User> = [];
  systemLoading = false;
  systemDrawer = false;
  system = new System();
  systems: Array<System> = [];
  countries: CountryServices.CountryCode[] = [];
  permissionLoading = false;
  permissionDrawer = false;
  permission = new Permission();
  permissions: Array<Column> = [];
  columnLoading = false;
  columnDrawer = false;
  column = new Column();
  columns: Array<Column> = [];
  showSecret = false;
  secretKey = "";
  expanded: Array<number> = [];
  translate = {
    types: {
      boolean: "Lógico",
      date: "Fecha",
      number: "Número",
      string: "Texto",
      id: "Id",
    },
  };
  rankingSystems: RankingSystem[] = [];

  //New systems
  showColumn = false;
  showKey = false;
  showOffert = false;
  selectedSystem = {};

  search: string | null = null;
  filterColumns(columns: Column[], search: string | null) {
    if (!search) {
      return columns; // If there is no search input, return the original columns
    }

    // Convert the search input to lowercase for case-insensitive matching
    const lowerSearch = search.toLowerCase();

    // Filter columns based on whether any of the fields contain the search string
    return columns.filter(
      col =>
        col.name.toLowerCase().includes(lowerSearch) ||
        col.code.toLowerCase().includes(lowerSearch)
    );
  }

  getUsers() {
    this.userLoading = true;
    UserServices.find({
      order: "active:desc,name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        _.each(res.data.data, (u: User) => {
          u.permissionsIds = _.pluck(u.permissions, "id");
        });
        this.users = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.userLoading = false;
      });
  }

  deleteUser(id: number) {
    this.userLoading = true;
    UserServices.destroy(id)
      .then(res => {
        this.getUsers();
        Notify.successful(`${res.data.data.name} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.userLoading = false;
      });
  }

  updateUserPermissions(data: Array<number>, id: number) {
    UserServices.setPermissions(id, {
      permissions: data,
    })
      .then(() => {
        Notify.successful(`Permisos actualizados exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  newKeySecret(id: number) {
    SystemServices.newSecret(id)
      .then(res => {
        this.secretKey = res.data.data.secretKey;
        this.showSecret = true;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  openInNewTab(url: string) {
    window.open(url, "_blank");
  }

  getCountries() {
    CountryServices.findCountryCodes()
      .then(res => (this.countries = res))
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  findCurrentCountry(idCountry: number): string {
    const country = this.countries.find(country => country.id === idCountry);
    if (country) {
      return country.description;
    }

    return "";
  }

  getSystems(value?: string) {
    if (value) {
      this.showSecret = true;
      this.secretKey = value;
    }
    this.systemLoading = true;
    SystemServices.find({
      order: "active:desc,name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.systems = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.systemLoading = false;
      });
  }

  getRanking(
    { id, showImprovement }: SystemServices.System,
    expandedRows: SystemServices.System[]
  ) {
    const currentSystem = this.rankingSystems.find(system => system.id === id);
    if (!currentSystem && showImprovement) {
      SystemServices.ranking(id)
        .then(res => {
          const ranking = Math.round(res.data.data.ranking);
          this.rankingSystems.push({
            id: id,
            ranking: ranking,
          });
        })
        .catch(error => {
          Notify.gebServerError(error);
        });
    }
  }

  getRankingValue(systemId: number): number | undefined {
    return this.rankingSystems.find(system => system.id === systemId)?.ranking;
  }

  deleteSystem(id: number) {
    this.systemLoading = true;
    SystemServices.destroy(id)
      .then(res => {
        this.getSystems();
        Notify.successful(`${res.data.data.name} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.systemLoading = false;
      });
  }

  getPermissions() {
    this.permissionLoading = true;
    PermissionServices.find({
      order: "code:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.permissions = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.permissionLoading = false;
      });
  }

  deletePermission(id: number) {
    this.permissionLoading = true;
    PermissionServices.destroy(id)
      .then(res => {
        this.getPermissions();
        Notify.successful(`${res.data.data.name} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.permissionLoading = false;
      });
  }

  getColumns() {
    this.columnLoading = true;
    ColumnServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.columnLoading = false;
      });
  }

  deleteColumn(id: number) {
    this.columnLoading = true;
    ColumnServices.destroy(id)
      .then(res => {
        this.getColumns();
        Notify.successful(`${res.data.data.name} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.columnLoading = false;
      });
  }

  newDrawer(type: string) {
    this.create = true;
    switch (type) {
      case "user":
        this.user = new User();
        this.userDrawer = true;
        break;
      case "system":
        this.system = new System();
        this.systemDrawer = true;
        break;
      case "permission":
        this.permission = new Permission();
        this.permissionDrawer = true;
        break;
      case "column":
        this.column = new Column();
        this.columnDrawer = true;
        break;
    }
  }

  editDrawer(index: number, type: string) {
    this.create = false;
    switch (type) {
      case "user":
        this.user = this.users[index];
        this.userDrawer = true;
        break;
      case "system":
        this.system = this.systems[index];
        this.systemDrawer = true;
        break;
      case "permission":
        this.permission = this.permissions[index];
        this.permissionDrawer = true;
        break;
      case "column":
        this.column = this.columns[index];
        this.columnDrawer = true;
        break;
    }
  }

  closeDrawer(value: boolean) {
    this.userDrawer = value;
    this.systemDrawer = value;
    this.permissionDrawer = value;
    this.columnDrawer = value;
  }

  copyToClipboard() {
    const copyText = this.$refs["secretKey"] as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
    this.showSecret = false;
    Notify.successful("Llave secreta copiada en el portapapeles.");
  }

  copyString(url: string) {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Notify.successful("Copiado en el portapapeles.");
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  handleColumn(value: boolean, system: any) {
    this.showColumn = value;
    this.selectedSystem = system;
  }

  handleKey(value: boolean, system: any) {
    this.showKey = value;
    this.selectedSystem = system;
  }

  handleOffert(value: boolean, system: any) {
    this.showOffert = value;
    this.selectedSystem = system;
  }

  created() {
    const index = "13";
    const title = "Ajustes";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getUsers();
    this.getSystems();
    this.getColumns();
    this.getPermissions();
    this.getCountries();
  }

  testExpand(value1?: any, value2?: any, value3?: any) {
    console.log({ value1, value2, value3 });
  }
}
