import { Gondor } from "../Gondor";

const ACTIONS = {
  PERMISSION: "/permission/",
  PERMISSION_ITEM: "/permission/{id}/",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.PERMISSION, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.PERMISSION_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.PERMISSION, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.PERMISSION_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.PERMISSION_ITEM.replace("{id}", id));
}
