import { Gondor } from "../Gondor";

const ACTIONS = {
  USER: "/user/",
  USER_ITEM: "/user/{id}/",
  USER_PERMISSION: "/user/{id}/permission",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.USER, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.USER_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.USER, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.USER_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.USER_ITEM.replace("{id}", id));
}

export function setPermissions(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.USER_PERMISSION.replace("{id}", id), body);
}
