
import { Component, Prop, Vue } from "vue-property-decorator";
import Regex from "@/utils/regex";
import * as SystemServices from "@/api/helpers/System";
import * as CountryServices from "@/api/helpers/Country";
import System from "@/models/System";
import Notify from "@/utils/notifications";

@Component
export default class SystemDrawer extends Vue {
  @Prop() system!: System;
  @Prop() create!: boolean;
  @Prop() show!: boolean;

  countries: Array<CountryServices.CountryCode> = [];
  loading = false;
  loadingCountries = true;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    url: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: Regex.url,
        message: "No es una url válida.",
      },
    ],
    countryId: [
      {
        required: true,
        message: "Tiene que seleccionar 1 pais",
        trigger: "change",
      },
    ],
  };

  mounted() {
    CountryServices.findCountryCodes()
      .then(res => (this.countries = res))
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loadingCountries = false;
      });
  }

  createSystem() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        SystemServices.create(this.system)
          .then(res => {
            this.$emit("newSystem", res.data.data.secretKey);
            Notify.successful("Sistema creado exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateSystem() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.system && this.system.id)
          SystemServices.update(this.system.id, this.system)
            .then(() => {
              Notify.successful("Sistema actualizado exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    return this.create ? "Crear nuevo sistema" : "Actualizar sistema";
  }

  get showDrawer() {
    return this.show;
  }
}
