
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as ColumnServices from "@/api/helpers/Column";
import ColumnForm from "./ColumnForm.vue";
import Notify from "@/utils/notifications";
import Column from "@/models/Column";

interface System {
  id: number;
  name: string;
  url: string;
  keyId: string;
  active: boolean;
  showImprovement: boolean;
  createdAt: string;
  updatedAt: string;
  countryId: number;
}

@Component({
  components: {
    ColumnForm,
  },
})
export default class ColumnDrawer extends Vue {
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: true }) system!: System;

  create = true;
  loading = false;
  columns: ColumnServices.Column[] = [];

  column = new Column();

  showDialog = false;
  loadingForm = false;

  closeDrawer() {
    this.$emit("closeDrawer", true);
  }

  handleDialog(status: boolean) {
    this.showDialog = status;
  }

  editColumn(id: number) {
    const column = this.columns.find(column => column.id === id);
    if (column) {
      this.column = ({
        ...new Column(),
        id: column.id,
        name: column.name,
        code: column.code,
        dataType: column.dataType,
        catalog: column.catalog,
        previousAnswer: column.previousAnswer,
        systems: [
          {
            systemId: this.system.id,
            System: {
              id: this.system.id,
              name: this.system.name,
              url: this.system.url,
              keyId: this.system.keyId,
              active: this.system.active,
              showImprovement: this.system.showImprovement,
              countryId: this.system.countryId,
            },
          },
        ],
      } as unknown) as Column;
    }
    this.handleDialog(true);
  }

  findColumns(systemId: number) {
    this.loading = true;
    ColumnServices.find({ system: systemId })
      .then(res => {
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch("system.id", { immediate: true })
  onSystemChange(newSystemId: number) {
    if (newSystemId) {
      this.findColumns(newSystemId);
    }
  }

  get titleDrawer() {
    return `Columnas ${this.system.name}`;
  }

  get titleDialog() {
    return `${this.create ? "Crear" : "Actualizar"} llave`;
  }
}
