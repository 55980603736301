import { Gondor, Response } from "../Gondor";

export interface OfferType {
  id: number;
  name: string;
  description: null | string;
  customParams: null | string;
  createdAt: Date;
  updatedAt: Date;
}

interface OfferTypeSchema {
  name: string;
  description?: string;
  customParams?: string;
}

interface OfferTypeSystemSchema {
  offerTypeId: number;
  systemId: number;
}

interface OfferTypeColumnSchema {
  offerTypeId: number;
  columnId: number;
}

const ACTIONS = {
  OFFER_TYPE: "/offerType",
  ITEM: "/offerType/{offerTypeId}",
  SYSTEM: "/offerTypeSystem",
  COLUMN: "/offerTypeColumn",
  COLUMN_ITEM: "/offerTypeColumn/{id}",
};

export const create = (body: OfferTypeSchema) => {
  return Gondor.API.post<Response<OfferType>>(ACTIONS.OFFER_TYPE, body);
};

export const findAll = (params?: object) => {
  return Gondor.API.get<Response<OfferType>>(ACTIONS.OFFER_TYPE, { params });
};

export const update = (offerTypeId: number, body: Partial<OfferTypeSchema>) => {
  return Gondor.API.patch(
    ACTIONS.ITEM.replace("{offerTypeId}", String(offerTypeId)),
    body
  );
};

export const addSystem = (body: OfferTypeSystemSchema) => {
  return Gondor.API.post(ACTIONS.SYSTEM, body);
};

export const addColumn = (body: OfferTypeColumnSchema) => {
  return Gondor.API.post(ACTIONS.COLUMN, body);
};

export const deleteColumn = (columnId: number) => {
  return Gondor.API.delete(
    ACTIONS.COLUMN_ITEM.replace("{id}", String(columnId))
  );
};
