export default class KeySystem {
  id?: number;
  key!: string;
  name!: string;
  description?: string;
  dataType!: string;
  systemId!: number;
  createdAt?: Date;
  updatedAt?: Date;
}
