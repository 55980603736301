import Column from "./Column";

class OfferTypeColumn extends Column {
  offerTypeColumnId?: number;
}

export default class OfferType {
  id?: number;
  name!: string;
  description?: string;
  customParams?: any;
  columns: OfferTypeColumn[] = [new OfferTypeColumn()];
  createdAt?: Date;
  updatedAt?: Date;
}
